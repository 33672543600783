/**
 * Fetches local images from content folders
 *
 * @param {string} url frontmatter background value. Can be remote url
 * @param {string} slug content slug
 * @param {string} folder relative to /src/content. Defaults to "blog"
 * @returns {string} generated background url
 */
export const getContentBackground = async (
  url: string,
  slug: string,
  folder: "blog" | "work" = "blog",
): Promise<string> => {
  if (url.startsWith("https://")) return url;

  let images;
  const path = `/src/content/${folder}/${slug}${url.slice(1)}`;
  switch (folder) {
    case "work":
      images = import.meta.glob<{ default: ImageMetadata }>(
        "/src/content/work/**/*.{png,jpeg,jpg,avif,webp}",
      );
      break;
    default:
      images = import.meta.glob<{ default: ImageMetadata }>(
        "/src/content/blog/**/*.{png,jpeg,jpg,avif,webp}",
      );
  }

  const bg = (await images[path]()).default.src;
  return bg;
};
